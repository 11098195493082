/* 
 * @Author: 星空下的YZY   
 * @Date: 2022-12-06 22:24:45    
 * @Last Modified by: 星空下的YZY
 * @Last Modified time: 2023-04-06 00:27:41
 * @Blog: 226yzy.com  
 * @Email: 2119388133@qq.com  
 */ 
<template>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  box-sizing: border-box;
  text-decoration:none;
}
body {
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
}
</style>
