/* 
 * @Author: 星空下的YZY   
 * @Date: 2022-12-06 22:25:14    
 * @Last Modified by: 星空下的YZY
 * @Last Modified time: 2022-12-26 00:20:01
 * @Blog: 226yzy.com  
 * @Email: 2119388133@qq.com  
 */
export default {
    mytest() {
        console.log("测试")
    },
    mobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        return flag && flag.length && flag.length > 0;
    },
}