/* 
 * @Author: 星空下的YZY   
 * @Date: 2022-12-06 22:26:13    
 * @Last Modified by: 星空下的YZY
 * @Last Modified time: 2023-04-10 23:28:52
 * @Blog: 226yzy.com  
 * @Email: 2119388133@qq.com  
 */
import {
  createStore
} from 'vuex'

export default createStore({
  state: {
    web_info: [],
    userList: [],
    articleList: [],
    pageList:[],
    webArticleList: [],
    WebComponent: [],
    articleEdit: [],
    pageEdit: [],
    webArticlCount: 0,
    toolbar: {
      visible: false,
      enter: true
    },
    user_name: "",
    user_id: "",
    user_type: ""

  },
  getters: {},
  mutations: {
    changeToolbarStatus(state, toolbarState) {
      state.toolbar = toolbarState;
    },
  },
  actions: {},
  modules: {},
})