/* 
 * @Author: 星空下的YZY   
 * @Date: 2022-12-06 22:26:06    
 * @Last Modified by: 星空下的YZY
 * @Last Modified time: 2023-04-11 19:07:28
 * @Blog: 226yzy.com  
 * @Email: 2119388133@qq.com  
 */
import {
  createRouter,
  createWebHistory,
} from 'vue-router'

const routes = [{
    path: '/',
    name: 'home',
    component: () => import('../views/IndexView.vue'),
    meta: {
      title: '首页'
    },

    children: [{
      path: '/',
      component: () => import('../views/main/index.vue'),
    }, ]
  }, {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('../views/404.vue'),
    meta: {
      title: '404'
    },
  },
  {
    path: '/article/:sss',
    name: '文章',
    component: () => import('../views/main/article.vue'),
    meta: {
      title: '文章'
    },
  },
  {
    path: '/page/:sss',
    name: '页面',
    component: () => import('../views/main/page.vue'),
    meta: {
      title: '页面'
    },
  },
  {
    path: '/demo/:sss',
    name: 'demo',
    component: () => import('../components/layout/demo.vue'),
    meta: {
      title: 'demo'
    },
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('../views/loginView.vue'),
    meta: {
      title: '登录'
    },
  },
  {
    path: '/registered',
    name: '注册',
    component: () => import('../views/registeredView.vue'),
    meta: {
      title: '注册'
    },
  },
  {
    path: '/manage',
    name: '后台管理',
    redirect: '/articleList',
    component: () => import('../views/admin/ManageView.vue'),
    meta: {
      title: '后台管理'
    },
    children: [{
      path: "/articleList",
      name: "文章管理",
      component: () => import('../views/admin/articleList.vue')
    }, {
      path: "/webBaseInfo",
      name: "网站设置",
      component: () => import('../views/admin/webBaseInfo.vue')
    }, {
      path: "/createArticle",
      name: "新建文章",
      component: () => import('../views/admin/createArticle.vue')
    }, {
      path: "/articleEdit/:sss",
      name: "文章编辑",
      component: () => import('../views/admin/articleEdit.vue')
    }, {
      path: "/userList",
      name: "用户管理",
      component: () => import('../views/admin/userList.vue')
    }, {
      path: "/pageList",
      name: "页面管理",
      component: () => import('../views/admin/pageList.vue')
    }, {
      path: "/createPage",
      name: "新建页面",
      component: () => import('../views/admin/createPage.vue')
    }, {
      path: "/pageEdit/:sss",
      name: "页面编辑",
      component: () => import('../views/admin/pageEdit.vue')
    }, {
      path: "/menuEdit",
      name: "菜单栏管理",
      component: () => import('../views/admin/menuEdit.vue')
    }, {
      path: "/individuation",
      name: "个性化设置",
      component: () => import('../views/admin/individuation.vue')
    }]
  },
]

const router = createRouter({
  history: createWebHistory("/"), //createWebHistory(process.env.BASE_URL), //createWebHistory createWebHashHistory
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})

export default router