/* 
 * @Author: 星空下的YZY   
 * @Date: 2022-12-06 22:24:51    
 * @Last Modified by: 星空下的YZY
 * @Last Modified time: 2023-05-04 12:02:22
 * @Blog: 226yzy.com  
 * @Email: 2119388133@qq.com  
 */
import {
    createApp
} from "vue"
import App from "./App.vue"
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import "/src/assets/css/element-plus.css"
import "/src/assets/css/markdown-highlight.css"
import axios from 'axios'
import VueAxios from 'vue-axios'
import "/src/assets/css/index.css"
import "/src/assets/css/animation.css"
import "/src/assets/css/fontawesome.min.css"
import mytools from './api/mytools'
import busuanzi from './api/busuanzi.pure.mini'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'



const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(VueAxios, axios)
app.use(mavonEditor)
app.use(busuanzi)
app.mount('#app')

app.config.globalProperties.$mytools = mytools

axios.defaults.baseURL = 'https://api.226yzy.com/' //https://api.226yzy.com/ 'http://localhost:8085/'